import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/manages",
    name: "manages",
    component: () => import("../views/ManagesView.vue"),
    children: [
      {
        path: "/manages/information",
        name: "manage_information",
        props: true,
        component: () => import("@/components/manageView/information.vue"),
      },
      {
        path: "/manages/banner",
        name: "manage_banner",
        props: true,
        component: () => import("@/components/manageView/banner.vue"),
      },
      {
        path: "/manages/intropage",
        name: "manage_intropage",
        props: true,
        component: () => import("@/components/manageView/intropage.vue"),
      },
      {
        path: "/manages/clip_vdo",
        name: "manage_clip_vdo",
        props: true,
        component: () => import("@/components/manageView/clip_vdo.vue"),
      },
      {
        path: "/manages/activity",
        name: "manage_activity",
        props: true,
        component: () => import("@/components/manageView/activity.vue"),
      },
      {
        path: "/manages/picture",
        name: "manage_picture",
        props: true,
        component: () => import("@/components/manageView/picture.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/resumeSchool",
    name: "resumeSchool",
    component: () => import("../views/ResumeSchoolView.vue"),
  },
  {
    path: "/resumeBoss",
    name: "resumeBoss",
    component: () => import("../views/ResumeBossView.vue"),
  },
  {
    path: "/teachers",
    name: "teachers",
    component: () => import("../views/teachersView.vue"),
  },
  {
    path: "/contextus",
    name: "contextus",
    component: () => import("../views/ContextUsView.vue"),
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("../views/ActivityView.vue"),
  },
  {
    path: "/news/:newsID",
    name: "newsDetail",
    props: true,
    component: () => import("@/components/homeView/ShowNewsDetail.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
